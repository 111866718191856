/* eslint-disable */
import 'babel-polyfill';
import 'whatwg-fetch';
import 'loadcss-core';
import 'loadcss-polyfill';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .catch(function(err) {
      console.log('Service Worker registration failed: ', err);
    });
}

import(/* webpackChunkName: "netc-core" */ '@netc/core/src/js/index');
import(/* webpackChunkName: "components" */ './components');
import(/* webpackChunkName: "app" */ './angular');
